<script>
	import { storage_settings } from '$lib/stores'

	// import { cookieSettings } from '$lib/stores/local'
	// export let showCookieWidget = false
	// TODO: Add data-sveltekit-reload to *every* '/kontakt' link (https://kit.svelte.dev/docs/link-options#data-sveltekit-reload)
</script>

<footer class="relative z-10 mt-auto bg-gray-900">
	<div
		class="mx-auto max-w-md overflow-hidden px-4 py-12 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8"
	>
		<nav class="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
			<div class="px-5 py-2">
				<a
					data-sveltekit-reload
					href="/kontakt"
					class="text-sm font-medium text-gray-400 hover:text-gray-300"
				>
					Kontakt
				</a>
			</div>

			<div class="px-5 py-2">
				<a
					rel="external"
					target="blank"
					href="https://hiral.onlyfy.jobs/login"
					class="text-sm font-medium text-gray-400 hover:text-gray-300"
				>
					Kandidaten-Login
				</a>
			</div>

			<div class="px-5 py-2">
				<a
					on:click|preventDefault={() => {
						if ($storage_settings) $storage_settings.is_banner_visible = true
					}}
					href="/datenschutz"
					class="text-sm font-medium text-gray-400 hover:text-gray-300"
				>
					Cookies&nbsp;🍪
				</a>
			</div>

			<div class="px-5 py-2">
				<a href="/impressum" class="text-sm font-medium text-gray-400 hover:text-gray-300">
					Impressum
				</a>
			</div>

			<div class="px-5 py-2">
				<a href="/datenschutz" class="text-sm font-medium text-gray-400 hover:text-gray-300">
					Datenschutz
				</a>
			</div>

			<div class="px-5 py-2">
				<a href="/karriere" class="text-sm font-medium text-gray-400 hover:text-gray-300">
					Karriere bei hiral&nbsp;🔥
				</a>
			</div>
		</nav>
		<div class="mt-8 flex justify-center space-x-6">
			<a
				href="https://www.linkedin.com/company/hiral"
				target="_blank"
				rel="external"
				class="text-gray-400 hover:text-gray-300"
			>
				<span class="sr-only">LinkedIn</span>
				<svg class="h-6 w-6" fill="currentColor" viewBox="0 0 72 72" aria-hidden="true">
					<path
						fill-rule="evenodd"
						d="M8 72h56c4.389 0 8-3.611 8-8V8c0-4.389-3.611-8-8-8H8C3.611 0 0 3.611 0 8v56c0 4.389 3.611 8 8 8zm54-10H51.316V43.802c0-4.99-1.896-7.777-5.845-7.777-4.296 0-6.54 2.901-6.54 7.777V62H28.632V27.333H38.93v4.67s3.096-5.729 10.453-5.729c7.353 0 12.617 4.49 12.617 13.777V62zM11.033 27.333V62h10.735V27.333H11.033zm5.316-4.539h.001-.001c-3.507 0-6.349-2.864-6.349-6.397C10 12.864 12.842 10 16.35 10c3.507 0 6.347 2.864 6.347 6.397 0 3.533-2.84 6.397-6.348 6.397z"
						clip-rule="evenodd"
					/>
				</svg>
			</a>

			<a
				href="https://www.xing.com/pages/hiralgmbh"
				target="_blank"
				rel="external"
				class="text-gray-400 hover:text-gray-300"
			>
				<span class="sr-only">Xing</span>
				<svg
					class="h-6 w-6"
					fill-rule="evenodd"
					stroke-linejoin="round"
					stroke-miterlimit="2"
					clip-rule="evenodd"
					viewBox="0 0 24 24"
				>
					<g fill="currentColor">
						<path
							d="M3.965 4.74c-.208 0-.383.073-.472.216-.091.148-.077.339.02.531l2.34 4.052c.004.008.004.012 0 .019l-3.677 6.491c-.096.191-.092.383 0 .531a.51.51 0 00.452.236H6.09c.517 0 .767-.35.944-.669l3.736-6.608-2.379-4.15c-.173-.307-.433-.649-.964-.649H3.965zM19.22 24l.46-.22c.093-.148.09-.344-.007-.536l-4.88-8.916a.018.018 0 010-.022L22.457.754c.096-.19.099-.386.007-.534-.088-.142-.252-.22-.46-.22h-3.497c-.517 0-.741.325-.927.659L9.878 14.318l4.918 9.023c.172.307.437.659.967.659h3.457z"
						/>
					</g>
				</svg>
			</a>

			<a
				href="https://www.facebook.com/hellohiral"
				target="_blank"
				rel="external"
				class="text-gray-400 hover:text-gray-300"
			>
				<span class="sr-only">Facebook</span>
				<svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
					<path
						fill-rule="evenodd"
						d="M10.125 23.95C4.391 23.05 0 18.081 0 12.096c0-6.623 5.377-12 12-12s12 5.377 12 12c0 5.985-4.391 10.953-10.125 11.854a12.316 12.316 0 01-1.843.146h-.113a12.012 12.012 0 01-1.794-.145zm3.75 0v-8.384h2.796l.532-3.47h-3.328V9.845c0-.949.464-1.875 1.956-1.875h1.513V5.017s-1.373-.235-2.686-.235c-2.74 0-4.533 1.66-4.533 4.67v2.644H7.077v3.47h3.048v8.384c.585.092 1.184.142 1.794.146h.113a12.168 12.168 0 001.843-.146z"
						clip-rule="evenodd"
					/>
				</svg>
			</a>

			<a href="https://www.youtube.com/@hellohiral" class="text-gray-400 hover:text-gray-300">
				<span class="sr-only">Youtube</span>
				<svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
					<path
						fill-rule="evenodd"
						d="M24 2.618A2.62 2.62 0 0021.382 0H2.618A2.62 2.62 0 000 2.618v18.764A2.62 2.62 0 002.618 24h18.764A2.62 2.62 0 0024 21.382V2.618zM11.994 5.621s-5.697 0-7.128.372a2.356 2.356 0 00-1.61 1.621c-.371 1.43-.371 4.392-.371 4.392s0 2.972.371 4.38a2.32 2.32 0 001.61 1.61c1.442.383 7.128.383 7.128.383s5.71 0 7.14-.372a2.265 2.265 0 001.598-1.61c.383-1.419.383-4.38.383-4.38s.012-2.973-.383-4.403a2.25 2.25 0 00-1.598-1.599c-1.43-.394-7.14-.394-7.14-.394zm-1.813 3.648l4.741 2.737-4.74 2.725V9.269z"
						clip-rule="evenodd"
					/>
				</svg>
			</a>
		</div>
		<p class="mt-8 text-center text-sm font-medium text-gray-400">
			&copy; {new Date().getFullYear()} hiral GmbH 🍕
		</p>
	</div>
</footer>
